import React from "react";
import {CompanyName, Description, KeyTechnologiesSection, Position} from "./sharedComponents";
import {ExperienceContainer} from "../sharedComponents";


const Nextechs = () => {
    return (

        <ExperienceContainer title="Work Experience">
            <CompanyName>
                Nextechs s.r.l.
            </CompanyName>
            <Position
                title="Lead Developer"
                startDate="January 2015"
                endDate="August 2017"/>
            <Position
                title="Internship/Master Thesis"
                startDate="September 2014"
                endDate="January 2015"/>
            <Description>
                <p>
                    Nextechs is specialized in building and providing B2B GPS tracking systems. During my time there, I
                    was involved in 2 interesting projects.
                </p>
                <p>
                    NIPS is an indoor positioning system based on UWB radio and publisher/subscriber
                    communication pattern on a physically distributed mesh. The project started as a proof of concept
                    and evolved into a commercial product, this allowed me to be part of all the phases of the
                    development.
                </p>
                <p>
                    After the positive experience with NIPS we created a team to build new applications on top of
                    the existing tracking system.
                    WMS is an integrated solution for waste-management services administration leveraging on
                    door-to-door waste pickup tracking using GPS and RFID technology.
                </p>
                <p>
                    Thanks to our contribution, our customers were able to:
                    <ul>
                        <li>
                            Manage the work shifts of operators dispatched on the field. More than 200 vehicles were
                            organized
                            and tracked throughout the daily service for our biggest customer.
                        </li>
                        <li>
                            Out-source customer relationship and ticketing to third-party call centers. Thanks to our
                            voice application the booking of some special pick-up services was completely automated
                            with no human intervention needed.
                        </li>
                        <li>
                            Collaborate with the citizenship in the waste management process through an integrated
                            mobile
                            app. The app allowed a citizen to retrieve information on the service, submit tickets and
                            book special services.
                        </li>
                    </ul>
                </p>

                <p>
                    WMS was running on Google Cloud Platform and serving 150 towns on the italian territory, covering
                    an overall population of 1.5 million citizens.
                </p>

            </Description>

            <KeyTechnologiesSection>
                Django, Django Rest Framework and Celery micro-services, pure python MQTT
                clients for IoT devices, AngularJS apps and company wide UI library, Teamcity based CI/CD on Google
                Cloud Platform (Kubernetes and Docker based), Android native and Hybrid development.
            </KeyTechnologiesSection>
        </ExperienceContainer>
    );
};


export default Nextechs;