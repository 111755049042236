import React from 'react';
import './App.css';
import CVPage from "./components/cv/CVPage";
import {ThemeProvider} from '@material-ui/core';
import theme from "./styleGuide/theme";


function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <CVPage/>
            </ThemeProvider>
        </div>
    );
}

export default App;
