import {createMuiTheme} from '@material-ui/core';
import {black50, black85, primary, secondary} from "./colors";

const typographyMarginBottom = "20px;"


const theme = createMuiTheme({

    typography: {
        fontFamily: 'Heebo',
        color: black85,
        h3: {
            fontSize: "2.5rem",
            fontWeight: "500",
            marginBottom: typographyMarginBottom
        },
        h4: {
            fontSize: "1.6rem",
            fontWeight: "500",
            marginBottom: typographyMarginBottom
        },
        h5: {
            fontSize: "1.2rem",
            fontWeight: "500",
            lineHeight: "2.0rem"
        },
        h6: {
            fontSize: "0.9rem",
            fontWeight: "500",
            lineHeight: "1.4rem"
        },
        subtitle1: {
            fontSize: "0.9rem",
            color: black50,
            lineHeight: "1.1rem"
        },
        subtitle2: {
            fontSize: "0.8rem",
            color: black50,
        },
        body1: {
            fontSize: "0.8rem",
            color: black85,
        },
        caption: {
            color: black50
        },
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: primary,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: secondary,
        }
    }
});


export default theme;