import React from "react";
import {CompanyName, Description, KeyTechnologiesSection, Position} from "./sharedComponents";
import {ExperienceContainer} from "../sharedComponents";


const VolkswagenDataLab = () => {
    return (
        <ExperienceContainer title="Work Experience">
            <CompanyName>
                Volkswagen AG.
            </CompanyName>
            <Position
                title="Software Engineer"
                startDate="September 2020"
                endDate="Today"
            />
            <Description>
                <p>
                    The Data:Lab is one of the Volkswagen digital labs included in the Software Innovation Center.
                    The goal of the labs is to build applications for the various brands of the Volkswagen Group,
                    in particular the Data:Lab focuses on data-intensive pipelines, machine learning and quantum computing.
                </p>
                <p>
                    So far I've been involved in two projects, one for the after-sales department and
                    one for the quality assurance on the production line.
                </p>
            </Description>

            <KeyTechnologiesSection>
                Apache Spark on Scala, React & Python (FastAPI), several new AWS services (QuickSight, EMR, Step Functions).
            </KeyTechnologiesSection>
        </ExperienceContainer>
    )

};


export default VolkswagenDataLab;