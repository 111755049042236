import React from "react";
import CVHeader from "./CVHeader";
import Divider from "../Divider";
import TrustYou from "./experiences/work/TrustYou";
import Nextechs from "./experiences/work/Nextechs";
import Education from "./experiences/education/Education";
import {Container} from "@material-ui/core";
import VolkswagenDataLab from "./experiences/work/VolkswagenDL";



const pdfStyle = {
};

const CVPage = () => {
    return (
        <Container>
            <CVHeader/>
            <div style={pdfStyle}>
                <Divider/>
                <VolkswagenDataLab/>
            </div>
            <div style={pdfStyle}>
                <TrustYou/>
            </div>
            <div style={pdfStyle}>
                <Nextechs/>
            </div>
            <div style={pdfStyle}>
                <Education/>
            </div>
        </Container>
    )
};


export default CVPage;
