import Typography from "@material-ui/core/Typography";
import React from "react";
import {primary} from "../../../../styleGuide/colors";

export const CompanyName = ({children}) => {
    return <Typography
        variant="h5"
        style={{color: primary}}>
        {children}</Typography>
};

export const Position = ({title, startDate, endDate}) => {
    return <div style={{marginBottom: '0.5rem'}}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1">{startDate} - {endDate}</Typography>
    </div>
};

export const Description = ({children}) => {
    return <Typography>{children}</Typography>
};

export const KeyTechnologiesSection = ({children}) => {
    return <div style={{backgroundColor: "#eeeeee", padding: "1rem"}}>
        <Typography>
            <b>Key Technologies:</b> {children}
        </Typography>
    </div>
};

