import React from "react";

const verticalDividerStyle = {
    height: '1px',
    background: '#aaaaaa',
    width: '100%'
};

const horizontalDividerStyle = {
    height: '1px',
    background: '#aaaaaa',
    width: '100%',
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft: "auto",
    marginRight: "auto"
};


const Divider = ({direction}) => {
    return <div style={direction === "vertical" ? verticalDividerStyle : horizontalDividerStyle}/>
};


export default Divider;