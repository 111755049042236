import Grid from "@material-ui/core/Grid";
import React from "react";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";


const style = {
    marginBottom: "1.8rem"
};

export const ExperienceContainer = ({title, children}) => {
    return (
        <Grid container spacing={3} style={style}>
            <Hidden smDown>
                <Grid item xs={3}>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                </Grid>
            </Hidden>
            <Grid item xs={12} md={9}>
                {children}
            </Grid>
        </Grid>
    );
};