import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {primary} from "../../styleGuide/colors";


const Title = () => {
    return (<Typography variant="h3" style={{color: primary}}>Nicola Sacco</Typography>)
};

const ContactDetails = () => {
    return (
        <Typography align="right" variant="subtitle2">
            nicola@nicolasacco.cloud
        </Typography>
    )
};

const ActualRole = () => {
    return (
        <Typography variant="h4">
            Software Engineer,<br/>
            with Leadership Experience
        </Typography>
    )
};

const Quote = () => {
    return (
        <Typography align="right" variant="subtitle2">
            <i>Building web & mobile applications and leading international
                teams in fast-growing environments introducing, promoting
                and enforcing agile best-practices.</i>
        </Typography>
    );
}


const verticalMargin = '20px';


const CVHeader = () => {

    return (
        <div>
            <Grid container alignItems="center" direction="row" style={
                {marginBottom: verticalMargin, marginTop: verticalMargin}}>
                <Grid item xs={5}>
                    <Title></Title>
                </Grid>
                <Grid item xs={1}>

                </Grid>
                <Grid item xs={6}>
                    <ContactDetails/>
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" direction="row" style={
                {marginBottom: verticalMargin, marginTop: verticalMargin}}>
                <Grid items xs={6}>
                    <ActualRole/>
                </Grid>
                <Grid item xs={1}>

                </Grid>
                <Grid items xs={5}>
                    <Quote/>
                </Grid>
            </Grid>
        </div>
    )
};

export default CVHeader;