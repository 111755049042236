import React from "react";
import {CompanyName, Description, KeyTechnologiesSection, Position} from "./sharedComponents";
import {ExperienceContainer} from "../sharedComponents";


const TrustYou = () => {
    return (
        <ExperienceContainer title="Work Experience">
            <CompanyName>
                TrustYou Gmbh.
            </CompanyName>
            <Position
                title="Principal Software Engineer"
                startDate="February 2020"
                endDate="September 2020"/>
            <Position
                title="Senior Software Engineer, Team & Tech Lead"
                startDate="September 2018"
                endDate="January 2020"/>
            <Position
                title="Software Engineer"
                startDate="September 2017"
                endDate="August 2018"/>
            <Description>
                <p>
                    TrustYou provides an online reputation management platform for Hotels.
                    We collect, aggregate, analyze and visualize quantitative and qualitative insights given the reviews
                    posted in the most famous providers (e.g. Google, TripAdvisor).
                    Thanks to our platform, hoteliers and general managers of big hotel-chains are able to take
                    actionable and data-driven decisions to improve their business.
                </p>
                <p>
                    As Principal Software Engineer, I was responsible for the technical coordination of 5 teams.
                    My main stakeholders were the CTO, the VP of Engineering, the Director of Product and the Team/Tech
                    leads of the Platform teams.<br/>
                    In order to keep a close contact with the teams I regularly participated to the development of
                    features as a contributing developer and in the technical specification phase.
                </p>
                <p>
                    In my previous role, as Team & Tech Lead, I was responsible for delivering high quality features,
                    facilitating the sprint deliveries and steering the technical evolution of the system in a
                    cross-functional and distributed team.
                </p>
                <p>
                    I am particularly proud of the following changes we introduced in our team:
                    <ul>
                        <li>
                            Feature flags. This practice allowed the delivery of features even when our
                            biggest customers were not able to dispatch the information about the changes at the same
                            speed of our development process.
                        </li>
                        <li>
                            Introduction of Backlog Refinement Meetings, including Designers and Product Managers.
                            The key result was a tremendous improvement of the feature specification alignment between
                            Product, Design and Development teams.
                        </li>
                        <li>
                            Migration to Docker of some legacy applications, the goal was to accelerate our
                            delivery process and improve the independence of the team.
                        </li>
                        <li>
                            Definition of the migration strategy to React for legacy systems.
                            Using Webpack we were able to integrate React components into JQuery legacy applications
                            opening the way for the gradual refactoring with no disruption of the service.
                        </li>
                        <li>
                            Increase of the automatic test coverage using Selenium Tests and feature specifications
                            based on the “Given When Then” syntax.
                        </li>
                    </ul>
                </p>

                <p>
                    Some remarkable “out of the sprint” projects I worked on are:
                    <ul>
                        <li>
                            TY UI: a company wide set of React components exported as ES6 classes using Rollup
                            bundler and distributed through npm packages.
                        </li>
                        <li>
                            TY cli: a python based command line tool to run our apps into Docker containers
                            through Docker Compose, manage configurations and secrets and facilitate new developers'
                            on-boarding.
                        </li>
                    </ul>
                </p>

                <p>
                    I was also part of the following initiatives:
                    <ul>
                        <li>
                            <b>Front End Chapter:</b> the purpose of the chapter is to drive the
                            technological choices for the Front-End tech stack of the company.
                        </li>
                        <li>
                            <b>Architecture Chapter:</b> the purpose of the chapter is to coordinate
                            the design and the evolution of our micro-services oriented architecture.
                        </li>
                    </ul>
                </p>
            </Description>

            <KeyTechnologiesSection>
                Python sync and async web-servers (Django, Tornado, Twisted), React apps and
                internal libraries distributed both in new and legacy systems through npm, Jenkins based CI/CD on
                Tsuru (Docker + Kubernetes management system).
            </KeyTechnologiesSection>
        </ExperienceContainer>
    )

};


export default TrustYou;